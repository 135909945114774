.PopoverCustom {
  :global {
    .ant-popover-inner{
      background: transparent;
      box-shadow: none;
      padding: 0;
    }
    .ant-popover-arrow{
      display: none;
    }
  }
}