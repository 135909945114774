.notiSetting {
    min-width: 312px;

    .notiTitle {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.41px;
        text-align: left;
        color: rgba(19, 34, 67, 1);
        // text-wrap: nowrap;
    }
}