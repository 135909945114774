/* CSS custom cho pagination */
.ant-pagination .ant-pagination-item-active {
    background-color: #6576ff !important;
    border-color: #6576ff !important;
}

.ant-pagination .ant-pagination-item-active a {
    color: white !important;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active) {
    background-color: white;
    border: 1px solid #d9d9d9;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active) a {
    color: black;
}

/* Hover state */
.ant-pagination .ant-pagination-item:hover {
    border-color: #6576ff;
}

.ant-pagination .ant-pagination-item:hover a {
    color: #6576ff;
}